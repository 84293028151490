/* before header */
.before-header {
  background-color: #E60278;
  padding: 10px;
}

.before-header .container {
  padding-left: 0;
}

.before-header a {
  color: #FFF;
  padding-right: 10px;
}

@media screen and (max-width: 400px) {
  .before-header a {
    display: block;
  }
}

/* header and navbar */
header {
  background-color: #FFF;
  width: 100%;
  z-index: 100000;
}

.navbar {
  margin-bottom: 0;
  min-height: 70px;
}

.navbar-brand {
  padding: 15px 15px 15px 0;
}

.navbar-brand img {
  max-width: 200px;
}

.navbar-nav > li > a {
  padding-top: 20px;
  color: #E60278;
}

.nav > li > a:focus, .nav > li > a:hover {
  background-color: transparent;
}

.navbar-toggle {
  background-color: #E60278;
  margin-right: 0;
}

.navbar-toggle .icon-bar {
  background-color: white;
}

/*cookie-alert*/
section.cookie-alert {
  background-color: #06c;
}

section.cookie-alert .alert {
  margin-bottom: 0;
  margin-top: 10px;
}

section.cookie-alert .alert-success {
  color: #fff;
  background-color: #06c;
  border-color: #06c;
}

section.cookie-alert a {
  display: block;
  margin: 10px 0px;
  color: #fff;
}

section.cookie-alert a.close {
  margin: 0;
  opacity: .7;
}

/* body, main and basic styles */
body {
  color: #666;
  font: 16px "Lato", sans-serif;
  line-height: 23px;
  word-wrap: break-word;
  font-weight: 300;
}

a {
  color: #0d3880;
}

a:hover, a:active {
  color: #E60278;
}

h1, h2, h3, h4, h5, h6 {
  color: #0d3880;
}

ul {
  list-style: none;
  -webkit-padding-start: 0;
}

p {
  margin: 0 0 20px;
}

.label {
  padding: 0;
  color: #666666;
  font-size: 100%;
  display: block;
  text-align: left;
  margin-bottom: 1em;
  margin-left: .2em;
}

input[type="text"], input[type="email"], input[type="search"], input[type="password"], input[type="tel"], input[type="url"], input[type="date"], textarea {
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  width: 100%;
  margin: 0 0 20px;
  background-color: #f9f9f9;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

input[type="reset"], input[type="button"], input[type="submit"] {
  background-color: #E60278;
  border: 1px solid #E60278;
  color: #FFF;
  font-weight: 700;
  cursor: pointer;
  margin-bottom: 30px;
  text-transform: uppercase;
  display: block;
  text-align: center;
  height: 40px;
  padding: 0 20px;
  margin-top: 5px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

main {
  padding: 30px;
}

/* blog posts */
.post {
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid #dddddd;
}

.meta {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #dddddd;
  font-size: 13px;
}

.meta i {
  color: #0d3880;
}

.author, .date {
  padding: 0 10px 0 5px;
}

p.title {
  font-size: 30px;
  line-height: 37px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #dddddd;
}

.post-excerpt {
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid #dddddd;
}

.post-excerpt a.button {
  font-weight: bold;
  text-transform: uppercase;
  color: #E60278;
  border-bottom: 1px solid #E60278;
  letter-spacing: .1em;
}

.pagination {
  display: block;
  min-height: 50px;
  border-bottom: 1px solid #dddddd;
}

.pagination a {
  text-transform: uppercase;
  color: #0d3880;
  border-bottom: 1px solid #0d3880;
  letter-spacing: .1em;
}

.pagination a.right {
  display: inline-block;
  float: right;
}

.pagination a.left {
  display: inline-block;
  float: left;
}

.pagination a:hover {
  text-decoration: none;
  font-weight: bold;
}

/* footer */
footer {
  background-color: #06c;
  padding: 20px 0;
  color: #FFF;
  font-size: 13px;
  line-height: 21px;
}

footer .container {
  margin-left: auto;
  max-width: 100%;
}

footer a {
  display: inline-block;
  color: #fff;
  padding: 5px;
}

footer .copyright {
  padding-top: 5px;
  float: right;
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.back-to-top a {
  display: block;
  background-color: #E60278;
  color: #FFF;
  width: 40px;
  height: 40px;
  border-radius: 3px;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
}

@media screen and (max-width: 830px) {
  footer .copyright {
    padding-bottom: 10px;
    display: block;
  }
}

/*section specific code */
/*styles for page-header partial*/
section.page-title-wrap {
  background-color: transparent !important;
  background-image: url(/images/slider.jpg);
  padding: 20px 0 30px;
  text-align: center;
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
  margin-top: -1px;
}

section.page-title-wrap h1.page-title {
  font-size: 30px;
  line-height: 37px;
  color: #FFF;
  font-weight: 700;
  margin: 0 auto;
  text-align: left;
  max-width: 1170px;
  padding-left: 15px;
}

/*hero only needed when including the hero partial*/
section.hero {
  text-align: center;
  background-color: transparent;
  background-image: url(/images/slider.jpg);
  padding: 0;
  min-height: 350px;
  background-size: cover;
  background-position: right bottom;
  background-repeat: no-repeat;
  margin-bottom: -15px;
}

section.hero .container {
  width: 100%;
}

section.hero .hero_message {
  text-align: center;
  margin: auto;
  padding: 3% 0 7%;
  width: 100%;
  background: rgba(0, 0, 0, 0.1);
}

section.hero .hero_message h1 {
  font-size: 3em;
  line-height: 1.5em;
  font-weight: bold;
  padding-bottom: 10px;
  color: #FFFFFF;
}

section.hero .hero_message p {
  color: #FFFFFF;
  font-size: 20px;
  line-height: 27px;
  padding-bottom: 40px;
  width: 100%;
  text-align: center;
  margin: auto;
}

section.hero .hero_message a.read-more {
  background-color: #E60278;
  border: 1px solid #E60278;
  color: white;
  text-align: center;
  text-transform: uppercase;
  padding: 10px 25px;
  border-radius: 3px;
  display: inline-block;
}

@media screen and (max-width: 1000px) {
  section.hero {
    min-height: auto;
    background-position: right bottom;
    text-align: center;
  }
  section.hero .hero_message h1 {
    font-size: 2.8em;
    line-height: 1.2em;
    padding-bottom: 10px;
    margin-top: 0;
  }
  section.hero .hero_message p {
    font-size: 20px;
    padding-bottom: 30px;
  }
}

/*home-intro is only only needed when included the signup-message partial */
section#home-intro {
  text-align: center;
  background-color: white;
  background-image: none;
  padding: 40px 0 60px;
  background-size: contain;
  background-position: top;
  height: 260px;
}

section#home-intro h3 {
  margin-bottom: 25px;
  padding: 0 10px;
  font-weight: 700;
  color: #0d3880;
  font-size: 2em;
}

section#home-intro a.call-to-action {
  background-color: #E60278;
  border: 1px solid #E60278;
  color: #FFF;
  text-align: center;
  text-transform: uppercase;
  padding: 10px 25px;
  border-radius: 3px;
  display: inline-block;
}

/*featured*/
#home-search {
  margin-bottom: 60px;
}

#home-search .featured-wrapper {
  text-align: center;
  border: 1px solid #E9E9E9;
  padding: 35px 20px 10px;
  margin-bottom: 30px;
}

#home-search .featured-wrapper .icon {
  background: #0d3880;
  position: relative;
  text-align: left;
  margin: 0 auto 35px;
  width: 48px;
  height: 80px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  -webkit-transform: rotate(30deg);
  -moz-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  -o-transform: rotate(30deg);
  transform: rotate(30deg);
}

#home-search .featured-wrapper .icon i {
  color: white;
  position: absolute;
  text-align: center;
  width: 80px;
  margin: 16px 0 0 -16px;
  line-height: 48px;
  z-index: 9;
  font-size: 21px;
  -webkit-transform: rotate(-30deg);
  -moz-transform: rotate(-30deg);
  -ms-transform: rotate(-30deg);
  -o-transform: rotate(-30deg);
  transform: rotate(-30deg);
}

#home-search .featured-wrapper .icon::before, #home-search .featured-wrapper .icon::after {
  position: absolute;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  background: inherit;
  content: '';
}

#home-search .featured-wrapper .icon::before {
  background: #0d3880;
  -webkit-transform: rotate(60deg);
  -moz-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  -o-transform: rotate(60deg);
  transform: rotate(60deg);
}

#home-search .featured-wrapper .icon::after {
  background: #0d3880;
  -webkit-transform: rotate(-60deg);
  -moz-transform: rotate(-60deg);
  -ms-transform: rotate(-60deg);
  -o-transform: rotate(-60deg);
  transform: rotate(-60deg);
}

#home-search .featured-wrapper a {
  display: block;
  margin-bottom: 20px;
}

/*by-sector, sectors only needed when including sector-module partial
by-location, locations only needed when including city-module partial*/
.by-sector, .by-location {
  margin: 40px auto;
}

.by-sector h3, .by-location h3 {
  margin: 20px 0 10px;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  font-weight: 700;
}

.by-sector ul.sectors, .by-sector ul.locations, .by-location ul.sectors, .by-location ul.locations {
  display: flex;
  flex-flow: wrap;
  flex-direction: row;
  justify-content: flex-start;
}

.by-sector ul.sectors li, .by-sector ul.locations li, .by-location ul.sectors li, .by-location ul.locations li {
  padding: 5px 10px 5px 0;
  flex-basis: 32%;
}

.by-sector ul.sectors li a i, .by-sector ul.locations li a i, .by-location ul.sectors li a i, .by-location ul.locations li a i {
  color: #E60278;
  width: 25px;
  padding-right: 10px;
}

@media screen and (max-width: 600px) {
  .by-sector ul.sectors li, .by-sector ul.locations li, .by-location ul.sectors li, .by-location ul.locations li {
    flex-basis: 48%;
  }
}

@media screen and (max-width: 500px) {
  .by-sector ul.sectors li, .by-sector ul.locations li, .by-location ul.sectors li, .by-location ul.locations li {
    flex-basis: 100%;
  }
}
