$primary-color: #E60278;
$secondary-color: #0d3880;

$featured-image: url(/images/slider.jpg);
$icon-color:#0d3880;
$before-header-bkg:$primary-color;
$before-header-links:white;

$header-background:#FFF;
$header-links:$primary-color;
$cookie-background:#06c;

$page-header-bkg:transparent;
$page-header-bkg-image:url(/images/slider.jpg);
$page-header:#FFF;

$hero-bkg:transparent;
$hero-message-bkg:rgba(0,0,0, .1);
$hero-h1-color:#FFFFFF;
$hero-p-color:#FFFFFF;
$hero-button-bkg:$primary-color;
$hero-button-text:white;

$toggle-icon-bar:white;
$line-color:#dddddd;

$home-search-bkg:white;

$signup-bkg:white;
$signup-bkg-image:none;
$signup-text:$secondary-color;
$signup-button-bkg:$primary-color;
$signup-button-text:#FFF;

$footer-bkg:#06c;

/* before header */
.before-header{
  background-color:$before-header-bkg;
  padding:10px;
  .container{
    padding-left: 0;
  }
  a{
    color:#FFF;
    padding-right:10px;
  }
  @media screen and (max-width:400px) {
    a {
      display: block;
    }
  }
}
/* header and navbar */
header{
  background-color: $header-background;
  width:100%;
  z-index: 100000;
}

.navbar{
  margin-bottom:0;
  min-height:70px;
}
.navbar-brand {
  padding: 15px 15px 15px 0;
  img {
    max-width:200px;
  }
}

.navbar-nav>li>a {
  padding-top:20px;
  color: $header-links;
}
.nav>li>a:focus, .nav>li>a:hover{
  background-color : transparent;
}
.navbar-toggle {
  background-color:$primary-color;
  margin-right:0;

  .icon-bar {
    background-color: $toggle-icon-bar;
  }
}
/*cookie-alert*/
section.cookie-alert {
  background-color: $cookie-background;
}
section.cookie-alert .alert{
  margin-bottom:0;
  margin-top:10px;
}
section.cookie-alert .alert-success {
  color: #fff;
  background-color: $cookie-background;
  border-color: $cookie-background;
}
section.cookie-alert a {
  display:block;
  margin:10px 0px;
  color:#fff;
}
section.cookie-alert a.close {
  margin:0;
  opacity: .7;
}
/* body, main and basic styles */
body{
  color:#666;
  font:16px "Lato", sans-serif;
  line-height: 23px;
  word-wrap: break-word;
  font-weight: 300;
}
a{
  color:$secondary-color;
}
a:hover, a:active{
  color:$primary-color;
}
h1, h2, h3, h4, h5, h6{
  color : $secondary-color;
}
ul{
  list-style: none;
  -webkit-padding-start:0;
}
p {
  margin:0 0 20px;
}
.label{
  padding:0;
  color:#666666;
  font-size:100%;
  display:block;
  text-align: left;
  margin-bottom: 1em;
  margin-left:.2em;
}
input[type="text"], input[type="email"], input[type="search"], input[type="password"], input[type="tel"], input[type="url"], input[type="date"], textarea{
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  width: 100%;
  margin: 0 0 20px;
  background-color: #f9f9f9;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
input[type="reset"], input[type="button"], input[type="submit"]{
  background-color: $primary-color;
  border:1px solid $primary-color;
  color: #FFF;
  font-weight: 700;
  cursor: pointer;
  margin-bottom: 30px;
  text-transform: uppercase;
  display: block;
  text-align: center;
  height: 40px;
  padding: 0 20px;
  margin-top: 5px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
main {
  padding:30px;
}
/* blog posts */
.post {
  padding:20px;
  margin-bottom: 20px;
  border: 1px solid $line-color;
}

.meta {
  padding-bottom:10px;
  margin-bottom :10px;
  border-bottom:1px solid $line-color;
  font-size:13px;
  i {
    color:$icon-color;
  }
}
.author, .date {
  padding:0 10px 0 5px;
}
p.title {
  font-size: 30px;
  line-height: 37px;
  margin-bottom :10px;
  padding-bottom:10px;
  border-bottom:1px solid $line-color;
}
.post-excerpt{
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid $line-color;

  a.button{
    font-weight: bold;
    text-transform: uppercase;
    color : $primary-color;
    border-bottom: 1px solid $primary-color;
    letter-spacing:.1em;
  }
}
.pagination {
  display:block;
  min-height :50px;
  border-bottom: 1px solid $line-color;

  a {
    text-transform: uppercase;
    color : $secondary-color;
    border-bottom: 1px solid $secondary-color;
    letter-spacing:.1em;
  }
  a.right {
    display:inline-block;
    float:right;
  }
  a.left {
    display:inline-block;
    float:left;
  }
  a:hover{
    text-decoration: none;
    font-weight:bold;
  }
}

/* footer */
footer{
  background-color:$footer-bkg;
  padding: 20px 0;
  color:#FFF;
  font-size: 13px;
  line-height: 21px;
  .container{
    margin-left: auto;
    max-width: 100%;
  }
  a {
    display:inline-block;
    color:#fff;
    padding:5px;
  }
  .copyright {
    padding-top:5px;
    float:right;
  }
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  a {
    display:block;
    background-color: $primary-color;
    color: #FFF;
    width: 40px;
    height: 40px;
    border-radius: 3px;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
  }
}
@media screen and (max-width:830px) {
  footer .copyright{
    padding-bottom: 10px;
    display: block;
  }
}
/*section specific code */
/*styles for page-header partial*/
section.page-title-wrap {
  background-color: $page-header-bkg !important;
  background-image: $page-header-bkg-image;
  padding               : 20px 0 30px;
  text-align            : center;
  background-attachment : fixed;
  background-position   : center center;
  background-size       : cover;
  margin-top            : -1px;

  h1.page-title {
    font-size: 30px;
    line-height: 37px;
    color: $page-header;
    font-weight: 700;
    margin:0 auto;
    text-align: left;
    max-width: 1170px;
    padding-left:15px;
  }
}

/*hero only needed when including the hero partial*/

section.hero {
  text-align: center;
  background-color: $hero-bkg;
  background-image: $featured-image;
  padding: 0;
  min-height: 350px;
  background-size: cover;
  background-position: right bottom;
  background-repeat: no-repeat;
  margin-bottom:-15px;
  .container{
    width:100%;
  }
  .hero_message {
    text-align: center;
    margin: auto;
    padding: 3% 0 7%;
    width: 100%;
    background: $hero-message-bkg;
    h1 {
      font-size: 3em;
      line-height: 1.5em;
      font-weight: bold;
      padding-bottom: 10px;
      color: $hero-h1-color;
    }
    p {
      color:$hero-p-color;
      font-size: 20px;
      line-height: 27px;
      padding-bottom :40px;
      width: 100%;
      text-align: center;
      margin: auto;
    }
    a.read-more {
      background-color: $hero-button-bkg;
      border:1px solid $hero-button-bkg;
      color: $hero-button-text;
      text-align: center;
      text-transform: uppercase;
      padding: 10px 25px;
      border-radius: 3px;
      display:inline-block;
    }

  }
}

@media screen and (max-width:1000px) {
  section.hero {
    min-height:auto;
    background-position:right bottom;
    text-align: center;
    .hero_message {
      h1 {
        font-size: 2.8em;
        line-height: 1.2em;
        padding-bottom: 10px;
        margin-top:0;
      }
      p {
        font-size:20px;
        padding-bottom: 30px;
      }
    }
  }
}


/*home-intro is only only needed when included the signup-message partial */
section#home-intro{
  text-align:center;
  background-color:$signup-bkg;
  background-image:$signup-bkg-image;
  padding: 40px 0 60px;
  background-size: contain;
  background-position: top;
  height: 260px;
}
section#home-intro h3{
  margin-bottom: 25px;
  padding:0 10px;
  font-weight: 700;
  color: $signup-text;
  font-size:2em;
}
section#home-intro a.call-to-action {
  background-color:$signup-button-bkg;
  border: 1px solid $signup-button-bkg;
  color:$signup-button-text;
  text-align: center;
  text-transform: uppercase;
  padding: 10px 25px;
  border-radius: 3px;
  display:inline-block;
}



/*featured*/
#home-search {
  margin-bottom:60px;
  .featured-wrapper {
    text-align: center;
    border: 1px solid #E9E9E9;
    padding: 35px 20px 10px;
    margin-bottom:30px;
    .icon {
      background: $secondary-color;
      position: relative;
      text-align: left;
      margin: 0 auto 35px;
      width: 48px;
      height: 80px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      -o-border-radius: 5px;
      border-radius: 5px;
      -webkit-transform: rotate(30deg);
      -moz-transform: rotate(30deg);
      -ms-transform: rotate(30deg);
      -o-transform: rotate(30deg);
      transform: rotate(30deg);
      i {
        color: white;
        position: absolute;
        text-align: center;
        width: 80px;
        margin: 16px 0 0 -16px;
        line-height: 48px;
        z-index: 9;
        font-size: 21px;
        -webkit-transform: rotate(-30deg);
        -moz-transform: rotate(-30deg);
        -ms-transform: rotate(-30deg);
        -o-transform: rotate(-30deg);
        transform: rotate(-30deg);
      }
    }
    .icon::before, .icon::after {
      position: absolute;
      width: inherit;
      height: inherit;
      border-radius: inherit;
      background: inherit;
      content: '';
    }
    .icon::before {
      background: $secondary-color;
      -webkit-transform: rotate(60deg);
      -moz-transform: rotate(60deg);
      -ms-transform: rotate(60deg);
      -o-transform: rotate(60deg);
      transform: rotate(60deg);
    }
    .icon::after {
      background: $secondary-color;
      -webkit-transform: rotate(-60deg);
      -moz-transform: rotate(-60deg);
      -ms-transform: rotate(-60deg);
      -o-transform: rotate(-60deg);
      transform: rotate(-60deg);
    }
    a{
      display: block;
      margin-bottom: 20px;
    }
  }
}

/*by-sector, sectors only needed when including sector-module partial
by-location, locations only needed when including city-module partial*/

.by-sector, .by-location {
  margin:40px auto;
  h3 {
    margin: 20px 0 10px;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    font-weight: 700;
  }
  ul.sectors, ul.locations {
    display: flex;
    flex-flow: wrap;
    flex-direction: row;
    justify-content: flex-start;
    li {
      padding: 5px 10px 5px 0;
      flex-basis: 32%;
      a i{
        color:$primary-color;
        width: 25px;
        padding-right: 10px;
      }
    }
    @media screen and (max-width:600px) {
      li {
        flex-basis: 48%;
      }
    }
    @media screen and (max-width:500px) {
      li {
          flex-basis: 100%;
        }
    }
  }
}